import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import HeroSection from "../components/herosection";
import Arrow from "../components/arrow";

import "../scss/privacy.scss";

const PrivacyPage = ({ data }) => {
    const metadescription = "Next&Co are an independent digital and traditional performance marketing agency, visit our site or call 1300 191 950.";
    const metatitle = "Privacy Policy Page - Next&Co";
    const metaurl = typeof window !== 'undefined' ? window.location.href : '';

    return (
        <>
          <Helmet>
            <title>{metatitle}</title>
            <meta name="description" content={metadescription}/>
            <meta name="title" content={metatitle} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={metaurl} />
            <meta property="og:title" content={metatitle} />
            <meta property="og:description" content={metadescription} />
            <meta property="og:image" content="facebook.png" />
            <meta property="twitter:url" content={metaurl} />
            <meta property="twitter:title" content={metatitle} />
            <meta property="twitter:description" content={metadescription} />
            <meta property="twitter:image" content="twitter.png" />
          </Helmet>
          <Arrow/>
          <div className="privacy-policy-page">
            <Layout>
              <HeroSection
                heading="Privacy Policy"
                image={data.file.childImageSharp.fluid}
              />
              <section id="row-one">
                <div className="outer-container">
                  <div className="container-max-width">
                    <div className="inner-container">
                      <div className="col">
                        <p>
                          As you browse our site, advertising cookies will be placed on your computer so that we can understand what you are interested in through pages you visit within the site and the time spent on each page. This information enables our advertising partners to present you with personalised and relevant advertising based on your preferences and behaviour. The techniques our partners employ do not collect personally identifiable information (PII) from our website such as your name, email address, postal address, or telephone number.
                        </p>
                        <p>
                          The personal information we collect is: Name, Phone and Email Address and is used for the purposes of marketing and / or getting in touch with you regarding your submitted enquiry on one of the various services we offer. This information can be modified and deleted by contacting us on 1300 191 950.
                        </p>
                        <p>
                          Under no circumstances do we rent, trade or share your personal information that we have collected with any other company for their marketing purposes without your consent. We reserve the right to communicate your personal information to any third party that makes a legally-compliant request for its disclosure.
                        </p>
                        <p>
                          Other than as set out in this Privacy Policy, we shall NOT sell or disclose your personal data to third parties without obtaining your prior consent unless this is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law. The Site may contain advertising of third parties and links to other sites or frames of other sites. Please be aware that we are not responsible for the privacy practices or the content of those third parties or other sites, nor for any third party to whom we transfer your data in accordance with our Privacy Policy.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </Layout>
          </div>
        </>
    );
};

export default PrivacyPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "hero.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 2880, quality: 100) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }
  }
`
